const Greeting = (props) => {
    return (
        <section id="hero-animated" className="hero-animated d-flex align-items-center">
        <div className="container d-flex flex-column justify-content-center align-items-center text-center position-relative" data-aos="zoom-out">
          <img src="assets/img/hero-carousel/hero-carousel-3.svg" className="img-fluid animated" />
          <h2>Welcome to <span>Moonlighting Projects</span></h2>
          <p>Find a fun project to work on here</p>
        </div>
      </section>
    );
  };
  
  export default Greeting;