import {useDispatch} from 'react-redux';
import React, { useState, useEffect } from 'react';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';


const Header = (props) => {
    const dispatch = useDispatch();

    const setHomePage = () => {
        dispatch({type: 'setHomePage', mainview: 'projectListView'});
    }

    return (
        <header id="header" className="header fixed-top sticked" data-scrollto-offset="0">
        <div className="container-fluid d-flex align-items-center justify-content-between">

        <a  className="logo d-flex align-items-center scrollto me-auto me-lg-0">
            <h1>Moonlight Projects</h1>
        </a>

        <nav id="navbar" className="navbar">
            <ul>
                <li><a className="nav-link scrollto" href="#team">Team</a></li>
                <li><a className="nav-link scrollto" href="#footer">Contact</a></li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle d-none"></i>
        </nav>

        <a className="btn-getstarted scrollto" onClick={setHomePage} >Find a Project</a>

        </div>
        </header>
    );
  };
  
  export default Header;
  