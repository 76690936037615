import Modal from 'react-modal';
import React, { useState } from 'react';
import {useDispatch} from 'react-redux';
import axios from 'axios';
import {useSelector} from 'react-redux';
import moonlightingAPI from '../utils/constants';

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

const CreateProjectModal = (props) => {
  const loggedInEmail = useSelector(state => state.loggedInEmail);
  const loggedInName = useSelector(state => state.loggedInName);
  const dispatch = useDispatch();

  const [title, setTitle] = useState("Enter title");
  const [email, setEmail] = useState("Enter email");
  const [description, setDescription] = useState("Enter description");
  const [hiddenProject, setHiddenProject] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const id = Date.now();
    const project = {
      id: id.toString(),
      email: email,
      title: title,
      description: description,
      hiddenProject: hiddenProject,
      loggedInName: loggedInName,
      loggedInEmail: loggedInEmail
    };
    axios
    .post(moonlightingAPI,{
      id: id.toString(),
      project: JSON.stringify(project)
    })
    .then((res) => {
      if (res.data.statusCode == 200) {
        console.log("***** success res = " + JSON.stringify(res));
        dispatch({type: 'createProject', newProject: project});
      } else {
        console.log("***** FAILED res = " + JSON.stringify(res));
      }
    })
    .catch((err) => console.log(" ********* error = "+JSON.stringify(err)));
    props.closeCreateProjectModal();
  }
    

  return (
    <Modal
      isOpen={props.createProjectModalIsOpen}
      onAfterOpen={props.afterCreateProjectModal}
      onRequestClose={props.closeCreateProjectModal}
      style={customStyles}
      contentLabel="Create a Project"
    >
      <div className="form" >
        <h2 >Please enter project information</h2>
        <form>
          <div className="form-group">
            <label for="projecttitle">Project Title</label>
            <input type="text" className="form-control" id="projecttitle" onInput={e => setTitle(e.target.value)} aria-describedby="emailHelp" placeholder={title}/>
          </div>
          <div className="form-group">
            <label for="description">Description</label>
            <textarea className="form-control" onInput={e => setDescription(e.target.value)} id="description" rows="5"></textarea>
          </div>
          <div class="form-group">
            <label for="createprojectemail">Email address</label>
            <input type="email" class="form-control" id="createprojectemail" onInput={e => setEmail(e.target.value)}  aria-describedby="emailHelp" placeholder={email}/>
            <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
          </div>
          <div className="form-check">
            <input type="checkbox" className="form-check-input"  id="hideproject" onClick={e => setHiddenProject(e.target.checked)}/>
            <label className="form-check-label" for="hideproject">Hidden Project</label>
            <br/>
            <small id="checkbox" className="form-text text-muted">click to hide your project to users who are not logged in</small>
          </div>
        </form>
        <div className="form div-form">
          <div className="row justify-content-center">
            <div className="col-xl-4 col-md-2 d-flex" data-aos="zoom-out">
              <a className="btn-getstarted" onClick={e => handleSubmit(e)}>Create Project</a>
            </div>
            <div  className="col-xl-4 col-md-2 d-flex" data-aos="zoom-out">
              <a className="btn-getstarted" onClick={props.closeCreateProjectModal} >close</a>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )

};

export default CreateProjectModal;