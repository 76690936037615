import {useSelector} from 'react-redux';
import CreateProjectModal from './createprojectmodal';

import React, { useState } from 'react';
import {useDispatch} from 'react-redux';
import { useGoogleLogin, googleLogout } from '@react-oauth/google';
import axios from 'axios';

const ProjectManager = (props) => {
  const dispatch = useDispatch();

  const [createProjectModalIsOpen, setCreateProjectModalIsOpen] = useState(false);
  
  const loggedInProfile = useSelector(state => state.loggedInProfile);

  const loginSuccess = (codeResponse) => {
    axios
    .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`, {
        headers: {
            Authorization: `Bearer ${codeResponse.access_token}`,
            Accept: 'application/json'
        }
    })
    .then((res) => {
      dispatch(
        {
          type: 'login', 
          loggedInProfile: res,
          loggedInEmail: res.data.email,
          loggedInName: res.data.name,
        }
      );
    })
    .catch((err) => console.log(err));
  }

  // log out function to log the user out of google and set the profile array to null
  const logOut = () => {
    googleLogout();
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => loginSuccess(codeResponse),
    onError: (error) => console.log('Login Failed:', error)
  });

  const createProject = () => {
    if (loggedInProfile) {
      setCreateProjectModalIsOpen(true);
    } else {
      login();
    }
  };
  

  const addProjectToList = () => {
    console.log("***** afterCreateProjectModal called");
  }

  const closeCreateProjectModal = () => {
    console.log("***** closeCreateProjectModal called");
    setCreateProjectModalIsOpen(false);
  }
  
  return (
      <section id="project-manager" className="featured-services">
      <div className="container">

        <div className="row justify-content-center">

          <div className="col-xl-4 col-md-2 d-flex" data-aos="zoom-out">
            <div className="service-item position-relative">
              <div className="icon"><i className="bi bi-activity icon"></i></div>
              <h4><a className="stretched-link">Find a Project</a></h4>
              <p>Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi</p>
            </div>
          </div>

          <div className="col-xl-4 col-md-2 d-flex" data-aos="zoom-out" data-aos-delay="200">
            <div className="service-item position-relative">
              <div className="icon"><i className="bi bi-bounding-box-circles icon"></i></div>
              <h4><a className="stretched-link" onClick={() => createProject()}>Register a Project</a></h4>
              <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore</p>
              <CreateProjectModal 
                createProjectModalIsOpen={createProjectModalIsOpen} 
                afterCreateProjectModal={() => addProjectToList()}
                closeCreateProjectModal={() => closeCreateProjectModal()}
                />
            </div>
          </div>

        </div>

      </div>
    </section>
  );
};
  
  export default ProjectManager;