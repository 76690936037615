const Project = (props) => {

    return (
      <div className="row justify-content-center">
        <div className="col-xl-4 col-md-2 d-flex" data-aos="zoom-out">
        <div className="service-item position-relative">
        <div className="icon"><i className="bi bi-activity icon"></i></div>
        <h4><a className="stretched-link">{props.title}</a></h4>
        <p>{props.description}</p>
        </div>
        </div>
      </div>
    )
};

export default Project;