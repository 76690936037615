const OurTeam = (props) => {
    return (
        <section id="team" className="team">
      <div className="container" data-aos="fade-up">

        <div className="section-header">
          <h2>Our Team</h2>
          <p>Please contact me if you want to help.</p>
        </div>

        <div className="row gy-5">

          <div className="col-xl-4 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="200">
            <div className="team-member">
              <div className="member-img">
                <img src="assets/img/team/jim-profile.png" className="img-fluid" alt="" />
              </div>
              <div className="member-info">
                <div className="social">
                  <a href="https://www.facebook.com/james.pizagno/"><i className="bi bi-facebook"></i></a>
                  <a href="https://www.linkedin.com/in/james-pizagno-99235a5/"><i className="bi bi-linkedin"></i></a>
                </div>
                <h4>James Pizagno</h4>
                <span>Software Engineer</span>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="200">
            <div className="team-member">
              <div className="member-img">
                <img src="assets/img/team/spongebob3.png" className="img-fluid" alt="" />
              </div>
              <div className="member-info">
                <h4>Your Name Here</h4>
                <span>Software Engineer</span>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="200">
            <div className="team-member">
              <div className="member-img">
                <img src="assets/img/team/spongebob3.png" className="img-fluid" alt="" />
              </div>
              <div className="member-info">
                <h4>Your Name Here</h4>
                <span>Software Engineer</span>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>
    )
  };
  
  export default OurTeam;