import './App.css';
import Header from "./components/header/header";
import Greeting from './components/greeting/greeting';
import ProjectManager from './components/projectmanager/projectmanager';
import OurTeam from './components/outteam/ourteam';
import Footer from './components/footer/footer';
import ProjectList from './components/projectlist/projectlist';
import moonlightingAPI from './components/utils/constants';
import {useSelector} from 'react-redux';
import axios from 'axios';
import {useDispatch} from 'react-redux';
import React, { useEffect } from 'react';

function App() {
  const mainview = useSelector(state => state.mainview);
  const renderCount = useSelector(state => state.renderCount);
  const dispatch = useDispatch();

  useEffect(() => {
    if (renderCount == 0) {
      axios
      .get(moonlightingAPI)
      .then((res) => {
        console.log("***** GET ProjectList success res = " + JSON.stringify(JSON.parse(res.data.body).Items));
        //dispatch({type: 'addProject', newProject: project});

        JSON.parse(res.data.body).Items.map((project) => {
          const projectParsed = JSON.parse(project.project.S);
          
          dispatch({type: 'addProject', newProject: {
            id: project.id.S,
            email: projectParsed.email,
            title: projectParsed.title,
            description: projectParsed.description,
            hiddenProject: projectParsed.hiddenProject,
            loggedInEmail: projectParsed.loggedInEmail,
            loggedInName: projectParsed.loggedInName,
          }})
          
        } )

      })
      .catch((err) => console.log(" ********* error = "+JSON.stringify(err)));
      dispatch({type: 'incRencerCount', newCount: 1});
    }
  }, [renderCount])
  
  return (
    <div className="App">
        <Header />
        
        {mainview === 'homepage' ? 
          <div>
            <Greeting />
            <ProjectManager />
            <OurTeam />
          </div>
          : 
          <div>
            <ProjectList />
          </div>
       }
        <Footer />
    </div>
  );
}

export default App;
