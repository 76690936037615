const Footer = (props) => {
    return (
<footer id="footer" class="footer">

<div className="footer-content">
  <div className="container">
    <div className="row">

      <div className="col-lg-3 col-md-6">
        <div className="footer-info">
          <h3>Moonlighting Project</h3>
          <p>
            Hechthausen, Germany<br/><br/>
            <strong>Email:</strong> jpizagno@gmail.com<br/>
          </p>
        </div>
      </div>

      <div class="col-lg-2 col-md-6 footer-links">
        <h4>Useful Links</h4>
        <ul>
          <li><i className="bi bi-chevron-right"></i> <a href="#">Home</a></li>
          <li><i className="bi bi-chevron-right"></i> <a href="#team">About us</a></li>
          <li><i className="bi bi-chevron-right"></i> <a href="#">Terms of service</a></li>
          <li><i className="bi bi-chevron-right"></i> <a href="#">Privacy policy</a></li>
        </ul>
      </div>

      <div className="col-lg-4 col-md-6 footer-newsletter">
        <h4>Our Newsletter</h4>
        <p>Coming Soon .. </p>
        <form action="" method="post">
          <input type="email" name="email"/><input type="submit" value="Subscribe"/>
        </form>

      </div>

    </div>
  </div>
</div>

<div className="footer-legal text-center">
  <div className="container d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between align-items-center">

    <div className="d-flex flex-column align-items-center align-items-lg-start">
      <div className="copyright">
        &copy; Copyright 2023 <strong><span> Moonlighting Project </span></strong>. All Rights Reserved
      </div>
      <div className="credits">
        {/*
         All the links in the footer should remain intact. 
         You can delete the links only if you purchased the pro version. 
         Licensing information: https://bootstrapmade.com/license/ 
          Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/herobiz-bootstrap-business-template/    
    */}
        Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
      </div>
    </div>

    <div className="social-links order-first order-lg-last mb-3 mb-lg-0">
      <a href="https://www.linkedin.com/in/james-pizagno-99235a5/" className="linkedin"><i className="bi bi-linkedin"></i></a>
    </div>

  </div>
</div>

</footer>
    );
  };
  
  export default Footer;