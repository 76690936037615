import { createStore} from "redux";

const initialState = {
    mainview: 'homepage', 
    loggedInProfile: null,
    loggedInEmail: null,
    loggedInName: null,
    renderCount: 0,
    projectListFull: [
      {
        id: "1690732877766",
        email: "jim@gmail.com",
        title: "project A",
        description: "description A",
        hiddenProject: false,
        loggedInEmail: "jpizagno@gmail.com",
        loggedInName: "Tester 1"
      },
      {
        id: "1690732878888",
        email: "pizagno@gmail.com",
        title: "project B",
        description: "description B",
        hiddenProject: false,
        loggedInEmail: "jpizagno@gmail.com",
        loggedInName: "Tester 2"
      },
    ]
};

const mainReducer = (state = initialState, action) => {

    if (action.type === "setHomePage") {
        return {
            mainview: action.mainview,
            loggedInProfile: state.loggedInProfile,
            loggedInEmail: state.loggedInEmail,
            loggedInName: state.loggedInName,
            renderCount: state.renderCount,
            projectListFull: state.projectListFull
        }
    }

    if (action.type === "login") {
        return {
            mainview: state.mainview,
            loggedInProfile: action.loggedInProfile,
            loggedInEmail: action.loggedInEmail,
            loggedInName: action.loggedInName,
            renderCount: state.renderCount,
            projectListFull: state.projectListFull
        }
    }

    if (action.type === "createProject") {
      // for a new project
        return {
            mainview: state.mainview,
            loggedInProfile: state.loggedInProfile,
            loggedInEmail: state.loggedInEmail,
            loggedInName: state.loggedInName,
            renderCount: state.renderCount,
            projectListFull: [...state.projectListFull, action.newProject]
        }
    }

    if (action.type === "addProject") {
      // when a project comes from the API backend, and has already been created
      console.log(" adding project:  action.newProject = " + JSON.stringify(action.newProject));
      return {
          mainview: state.mainview,
          loggedInProfile: state.loggedInProfile,
          loggedInEmail: state.loggedInEmail,
          loggedInName: state.loggedInName,
          renderCount: state.renderCount,
          projectListFull: [...state.projectListFull, action.newProject]
      }
    }

   if (action.type === "incRencerCount") {
      return {
        mainview: state.mainview,
        loggedInProfile: state.loggedInProfile,
        loggedInEmail: state.loggedInEmail,
        loggedInName: state.loggedInName,
        projectListFull: state.projectListFull,
        renderCount: action.newCount
    }
   }

    return state;

}

const store = createStore(mainReducer);

export default store;