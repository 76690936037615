import Project from "./project";
import {useSelector} from 'react-redux';

const ProjectList = (props) => {
    const projectListFull = useSelector(state => state.projectListFull);

    const projects = projectListFull.map((project) => 
        <Project title={project.title} description={project.description}/>
    );
    
    return (
      <section id="project-list" className="featured-services">
        <div className="container">
            {projects}
        </div>
      </section>
    )
}


export default ProjectList;